
import { Fragment, useState } from 'react'
import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import { Bars3Icon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Routes } from '../../../common/routing'
import WithOrganisations from '../../../components/Organisations/WithOrganisations'
import { Link } from "react-router-dom-v5-compat";
import { getEnvironmentVariables } from '../../../common/utils'
import { classNames } from '../../../common/css'

const NewMarketingNavBar = (props) => {
    const navigation = {
        categories: [
            {
                id: 'products',
                name: 'Products',
                featured: [
                    {
                        name: 'Enterprise Platform as a Service',
                        href: '#',
                        enabled: false,
                        imageSrc: 'https://cdn.midjourney.com/281caed7-1fd3-47aa-b5f5-03bc1073a0e4/0_3.webp',
                        imageAlt: 'Models sitting back to back, wearing Basic Tee in black and bone.',
                    },
                    {
                        name: 'Managed API Service',
                        href: '#',
                        enabled: false,
                        imageSrc: 'https://cdn.midjourney.com/7ca19178-03fc-4758-a008-4eb8c9cb039e/0_0.webp',
                        imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
                    },
                    {
                        name: 'API Goverance Toolkit',
                        href: '#',
                        enabled: false,
                        imageSrc: 'https://cdn.midjourney.com/7ca19178-03fc-4758-a008-4eb8c9cb039e/0_3.webp',
                        imageAlt: 'Model wearing minimalist watch with black wristband and white watch face.',
                    }
                ],
                sections: [
                    [
                        {
                            id: 'environment-features',
                            name: 'Environment Features',
                            items: [
                                {
                                    name: 'Environment Management',
                                    href: '#', enabled: false,
                                    icon: 'https://cdn.midjourney.com/94351cd9-fe0a-48bd-b932-221d5a4774e2/0_0.webp'
                                },
                                {
                                    name: 'Deployment Management',
                                    href: '#', enabled: false,
                                    icon: 'https://cdn.midjourney.com/8d367720-328f-49e2-9310-152827bb8d3d/0_0.webp'
                                },
                                {
                                    name: 'Infrastructure Orchestration',
                                    href: '#',
                                    enabled: false,
                                    icon: 'https://cdn.midjourney.com/7ca19178-03fc-4758-a008-4eb8c9cb039e/0_2.webp'
                                },
                                {
                                    name: 'Configuration Management',
                                    href: '#',
                                    enabled: false,
                                    icon: 'https://cdn.midjourney.com/25bc89fb-0f36-46dd-a59a-a712fc52e54a/0_3.webp'
                                },
                                {
                                    name: 'Secret Management',
                                    href: '#',
                                    enabled: false,
                                    icon: 'https://cdn.midjourney.com/c29f5375-25b9-44fb-862e-7bad1e0baeff/0_0.webp'
                                },
                                {
                                    name: 'Developer Self Service',
                                    href: '#',
                                    enabled: false,
                                    icon: 'https://cdn.midjourney.com/2c04c62e-e0a5-41fe-ab29-065f3862d35e/0_2.webp'
                                },
                                {
                                    name: 'GPU Orchestration',
                                    href: '#',
                                    enabled: false,
                                    icon: 'https://cdn.midjourney.com/91556fbf-6251-4258-b99d-cb11d053e5ee/0_0.webp'
                                },
                            ],
                        },
                        {
                            id: 'goverance',
                            name: 'Enterprise Governance and Control',
                            items: [
                                {
                                    name: 'Code Management and Scaffolding',
                                    href: '#',
                                    enabled: false,
                                    icon: 'https://cdn.midjourney.com/e9d1f6b0-9348-4b59-868e-7df380ea57fe/0_0.webp'
                                },
                                {
                                    name: 'API Goverance',
                                    href: '#',
                                    enabled: false,
                                    icon: 'https://cdn.midjourney.com/cfa0d928-73a9-41ea-aad4-c618901ccd82/0_0.webp'
                                }
                            ],
                        },
                    ],
                    [
                        {
                            id: 'devops',
                            name: 'Managed DevOps',
                            items: [
                                {
                                    name: 'Automated Build Pipelines',
                                    href: '#',
                                    enabled: false,
                                    icon: 'https://cdn.midjourney.com/cda83819-eb27-4c30-b72e-83b540cbb4dc/0_3.webp'
                                },
                                {
                                    name: 'Automated Release Pipelines',
                                    href: '#',
                                    enabled: false,
                                    icon: 'https://cdn.midjourney.com/38da720a-35be-424e-a320-6b3490e5ed56/0_2.webp'
                                },
                                {
                                    name: 'Deployment Gates',
                                    href: '#',
                                    enabled: false,
                                    icon: 'https://cdn.midjourney.com/c270128b-01bb-4851-a90f-7aaee66a1eb2/0_1.webp'
                                },
                            ],
                        },
                        {
                            id: 'monitoring',
                            name: 'Monitoring',
                            items: [
                                {
                                    name: 'API Monitoring',
                                    href: '#',
                                    enabled: false,
                                    icon: 'https://cdn.midjourney.com/539844df-6c6d-4de6-93bd-7e6d632e65a3/0_0.webp'
                                },
                                {
                                    name: 'Integration with Monotoring Platforms',
                                    href: '#',
                                    enabled: false,
                                    icon: 'https://cdn.midjourney.com/c73f8ce8-bf0f-4347-94da-9126ec554237/0_2.webp'
                                },
                            ],
                        },
                        {
                            id: 'organisation',
                            name: 'Policies and Data Structure',
                            items: [
                                {
                                    name: 'Multi Tenancy & Organisations',
                                    href: '#',
                                    enabled: false,
                                    icon: 'https://cdn.midjourney.com/deb8fa2f-50f4-4970-88e7-2a1ac56de499/0_3.webp'

                                },
                                {
                                    name: 'Platforms, Teams & AppSpaces',
                                    href: '#',
                                    enabled: false,
                                    icon: 'https://cdn.midjourney.com/1e6921f7-5f24-447f-8ee1-272477e20f8f/0_0.webp'
                                },

                            ],
                        }
                    ]
                ]
            },
            {
                id: 'solutions',
                name: 'Solutions',
                featured: [
                    {
                        name: 'Internal Developer Platform',
                        href: Routes.Website.Pages.Conversion.Content.Solutions.IDP,
                        enabled: false,
                        imageSrc: 'https://cdn.pellerex.com/public/ecosystem/web/content/internal-developer-platform/pellerex-platform-engineering.png',
                        imageAlt:
                            'Avoid spending a fortune reinventing the wheel for your software delivery teams. Instead let them focus on delivering your product fast using an IDP.',
                    },
                    {
                        name: 'Internal Developer Platform',
                        href: '#',
                        enabled: false,
                        imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg',
                        imageAlt:
                            'Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.',
                    },
                    {
                        name: 'Start up ecosystem',
                        href: '#',
                        enabled: false,
                        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg',
                        imageAlt: 'Drawstring top with elastic loop closure and textured interior padding.',
                    }
                ],
                sections: [
                    [
                        {
                            id: 'shoes',
                            name: 'Shoes & Accessories',
                            items: [
                                {
                                    name: 'Sneakers',
                                    enabled: false,
                                    href: '#'
                                },
                                {
                                    name: 'Boots',
                                    enabled: false,
                                    href: '#'
                                },
                                {
                                    name: 'Sandals',
                                    enabled: false,
                                    href: '#'
                                },
                                {
                                    name: 'Socks',
                                    enabled: false,
                                    href: '#'
                                },
                            ],
                        },
                        {
                            id: 'collection',
                            name: 'Shop Collection',
                            items: [
                                {
                                    name: 'Everything',
                                    enabled: false,
                                    href: '#'
                                },
                                {
                                    name: 'Core',
                                    enabled: false,
                                    href: '#'
                                },
                                {
                                    name: 'New Arrivals',
                                    enabled: false,
                                    href: '#'
                                },
                                {
                                    name: 'Sale',
                                    enabled: false,
                                    href: '#'
                                },
                            ],
                        },
                    ],
                    [
                        {
                            id: 'clothing',
                            name: 'All Clothing',
                            items: [
                                {
                                    name: 'Basic Tees',
                                    enabled: false,
                                    href: '#'
                                }
                                ,
                                {
                                    name: 'Artwork Tees',
                                    enabled: false,
                                    href: '#'
                                },
                                {
                                    name: 'Pants',
                                    enabled: false,
                                    href: '#'
                                },
                                {
                                    name: 'Hoodies',
                                    enabled: false,
                                    href: '#'
                                },
                                {

                                    name: 'Swimsuits',
                                    enabled: false,
                                    href: '#'
                                },
                            ],
                        },
                        {
                            id: 'accessories',
                            name: 'All Accessories',
                            items: [
                                {
                                    name: 'Watches',
                                    enabled: false,
                                    href: '#'
                                },
                                {
                                    name: 'Wallets',
                                    enabled: false,
                                    href: '#'
                                },
                                {
                                    name: 'Bags',
                                    enabled: false,
                                    href: '#'
                                },
                                {
                                    name: 'Sunglasses',
                                    enabled: false,
                                    href: '#'
                                },
                                {
                                    name: 'Hats',
                                    enabled: false,
                                    href: '#'
                                },
                                {
                                    name: 'Belts',
                                    enabled: false,
                                    href: '#'
                                },
                            ],
                        },
                    ],
                ],
            },
        ],
        pages: [
            {
                name: 'Pricing',
                enabled: true,
                href: Routes.Website.Pages.Conversion.Prices
            },
            {
                name: 'Ask an expert',
                enabled: true,
                href: Routes.Website.Pages.Conversion.TalkToExpert
            },
            {
                name: "Blogs",
                enabled: true,
                href: Routes.Website.Pages.Blogs.Directory
            }
        ]
    }

    const [open, setOpen] = useState(false)

    return (
        <div className="bg-gray-900">
            {/* Mobile menu */}
            <Transition.Root show={open} as={Fragment} className="lg:hidden">
                <Dialog as="div" className="relative" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                                <div className="flex px-4 pb-2 pt-5">
                                    <button
                                        type="button"
                                        className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>

                                {/* Links */}
                                <Tab.Group as="div" className="mt-2">
                                    <div className="">
                                        <Tab.List className="-mb-px flex space-x-8 px-4">
                                            {navigation.categories.map((category) => (
                                                <Tab
                                                    key={category.name}
                                                    className={({ selected }) =>
                                                        classNames(
                                                            selected ? 'border-indigo-600 text-indigo-600' : 'border-transparent text-gray-900',
                                                            'flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-lg'
                                                        )
                                                    }
                                                >
                                                    {category.name}
                                                </Tab>
                                            ))}
                                        </Tab.List>
                                    </div>
                                    <Tab.Panels as={Fragment}>
                                        {navigation.categories.map((category) => (
                                            <Tab.Panel key={category.name} className="space-y-10 text-lg px-4 pb-8 pt-10">
                                                <div className="space-y-4">
                                                    {category.featured.map((item, itemIdx) => (
                                                        <div
                                                            key={itemIdx}
                                                            className="group aspect-h-1 aspect-w-1 relative overflow-hidden rounded-md bg-gray-100"
                                                        >
                                                            <img
                                                                src={item.imageSrc}
                                                                alt={item.imageAlt}
                                                                className="object-cover object-center group-hover:opacity-75"
                                                            />
                                                            <div className="flex flex-col justify-end">
                                                                <div className="bg-white bg-opacity-60 p-4 text-base sm:text-md">
                                                                    <a href={item.href} className="font-medium text-gray-900">
                                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                                        {item.name}
                                                                    </a>
                                                                    <p aria-hidden="true" className="mt-0.5 text-gray-700 sm:mt-1">
                                                                        Learn more
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {category.sections.map((column, columnIdx) => (
                                                    <div key={columnIdx} className="space-y-10">
                                                        {column.map((section) => (
                                                            <div key={section.name}>
                                                                <p
                                                                    id={`${category.id}-${section.id}-heading-mobile`}
                                                                    className="font-medium text-gray-900"
                                                                >
                                                                    {section.name}
                                                                </p>
                                                                <ul
                                                                    role="list"
                                                                    aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                                                                    className="mt-6 flex flex-col space-y-6"
                                                                >
                                                                    {section.items.map((item) => (
                                                                        <li key={item.name} className="flex flex-row">
                                                                            <Bars3Icon className='h-5 w-5 mt-1 mr-3' />
                                                                            <a href={item.href} className="-m-2 block p-2 text-gray-500">
                                                                                {item.name}
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </Tab.Panel>
                                        ))}
                                    </Tab.Panels>
                                </Tab.Group>

                                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                                    {navigation.pages.map((page) => (
                                        <div key={page.name} className="flow-root">
                                            <a href={page.href} className="-m-2 block p-2 font-medium text-gray-900">
                                                {page.name}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            <header className="relative bg-gray-900 z-40 lg:my-2 md:my-2">
                <nav aria-label="Top" className="mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex h-16 items-center justify-around">
                        <div className="flex flex-1 items-center lg:hidden">
                            <button
                                type="button"
                                className="-ml-2 rounded-md bg-gray-900 p-2 text-gray-400"
                                onClick={() => setOpen(true)}
                            >
                                <span className="sr-only">Open menu</span>
                                <Bars3Icon className="h-6 w-6 bg-gray-900 text-white" aria-hidden="true" />
                            </button>

                            {/* <a href="#" className="ml-2 p-2 text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Search</span>
                                <MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />
                            </a> */}
                        </div>

                        {/* Logo */}
                        <a href={`${getEnvironmentVariables().REACT_APP_WEBSITE_PUBLIC_URL}`} className="flex">
                            <span className="sr-only">Pellerex Pty. Ltd.</span>
                            <img
                                className="h-8 w-auto"
                                src="https://cdn.pellerex.com/public/ecosystem/web/home/pellerex-logo.png"
                                alt=""
                            />
                        </a>

                        {/* Flyout menus */}
                        <Popover.Group className="hidden lg:block lg:self-stretch">
                            <div className="flex h-full space-x-8">
                                {navigation.categories.map((category) => (
                                    <Popover key={category.name} className="flex">
                                        {({ open }) => (
                                            <>
                                                <div className="relative flex">
                                                    <Popover.Button
                                                        className={classNames(
                                                            open ? 'text-indigo-600' : 'text-white hover:text-gray-500',
                                                            'text-lg relative z-10 flex items-center justify-center font-medium transition-colors duration-200 ease-out'
                                                        )}
                                                    >
                                                        {category.name}
                                                        <span
                                                            className={classNames(
                                                                open ? 'bg-indigo-600' : '',
                                                                'absolute inset-x-0 bottom-0 h-0.5 transition-colors duration-200 ease-out sm:mt-5 sm:translate-y-px sm:transform'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                    </Popover.Button>
                                                </div>

                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-200"
                                                    enterFrom="opacity-0"
                                                    enterTo="opacity-100"
                                                    leave="transition ease-in duration-150"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Popover.Panel className="absolute inset-x-0 top-full">
                                                        {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                                        <div className="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />

                                                        <div className="relative bg-white">
                                                            <div className="mx-auto max-w-7xl px-8">
                                                                <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-10">
                                                                    <div className="grid grid-cols-2 grid-rows-1 gap-8 text-md">
                                                                        {category.featured.map((item, itemIdx) => (
                                                                            <div
                                                                                key={item.name}
                                                                                className={classNames(
                                                                                    itemIdx === 0 ? 'aspect-w-2 col-span-2' : '',
                                                                                    'group aspect-w-1 aspect-h-1 relative overflow-hidden rounded-md bg-gray-100'
                                                                                )}
                                                                            >
                                                                                <img
                                                                                    src={item.imageSrc}
                                                                                    alt={item.imageAlt}
                                                                                    className="object-cover object-center group-hover:opacity-75"
                                                                                />
                                                                                <div className="flex flex-col justify-end">
                                                                                    <div className="bg-white bg-opacity-80 p-4 text-md">
                                                                                        <a href={item.href} className="font-medium text-gray-900">
                                                                                            <span className="absolute inset-0" aria-hidden="true" />
                                                                                            <span className='font-bold'>{item.name}</span>
                                                                                        </a>
                                                                                        <p aria-hidden="true" className="mt-0.5 text-gray-700 sm:mt-1">
                                                                                            Learn more
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    <div className="grid grid-cols-2 gap-x-8 gap-y-10 text-md text-gray-500">
                                                                        {category.sections.map((column, columnIdx) => (
                                                                            <div key={columnIdx} className="space-y-10">
                                                                                {column.map((section) => (
                                                                                    <div key={section.name}>
                                                                                        <p
                                                                                            id={`${category.id}-${section.id}-heading`}
                                                                                            className="font-bold text-gray-900"
                                                                                        >
                                                                                            {section.name}
                                                                                        </p>
                                                                                        <ul
                                                                                            role="list"
                                                                                            aria-labelledby={`${category.id}-${section.id}-heading`}
                                                                                            className="mt-4 space-y-4"
                                                                                        >
                                                                                            {section.items.map((item) => (
                                                                                                <li key={item.name} className="flex items-center">
                                                                                                    <img className='h-10 w-10 mt-1 mr-3' src={item.icon} />
                                                                                                    <a href={item.href} className="hover:text-gray-800">
                                                                                                        {item.name}
                                                                                                    </a>
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Popover.Panel>
                                                </Transition>
                                            </>
                                        )}
                                    </Popover>
                                ))}

                                {navigation.pages.map((page) => (
                                    <a
                                        key={page.name}
                                        href={page.href}
                                        className="flex items-center text-lg text-white hover:text-gray-500 transition-colors duration-200 ease-out"
                                    >
                                        {page.name}
                                    </a>
                                ))}
                            </div>
                        </Popover.Group>

                        <div className="flex items-center justify-end">
                            {/* Account */}
                            <div className="ml-4 flow-root lg:ml-6">
                                {!props.isAuthenticated && <Link
                                    to={`${getEnvironmentVariables().REACT_APP_APPLICATION_PUBLIC_URL
                                        }${Routes.Application.Identity.Login}`}
                                    className="text-lg font-semibold leading-6 text-gray-900 pb-2.5 pt-2 px-4 rounded-3xl bg-white hover:text-gray-400 transition-colors duration-200 ease-out"
                                >
                                    Log in <span aria-hidden="true">></span>
                                </Link>}
                                {props.isAuthenticated && <Link
                                    to={`${getEnvironmentVariables().REACT_APP_APPLICATION_PUBLIC_URL
                                        }${Routes.Application.Identity.Organisation.Selection}`}
                                    className="text-lg font-semibold leading-6 text-gray-900 pb-2.5 pt-2 px-4 rounded-3xl bg-white hover:text-gray-400 transition-colors duration-200 ease-out"
                                >
                                    Dashboard <span aria-hidden="true">></span>
                                </Link>}
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    )
}

export default WithOrganisations(NewMarketingNavBar);